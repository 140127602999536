// import { Link, graphql } from "gatsby";
// import * as React from "react"

// const CategoryList = ({ data }: any) => {
//     const categories = data.categoryList.nodes;
//     return <ul>{categories.map((item: any) => {
//         return <li key={item.id}>
//             <Link to={`/category/${item.slug}`}>
//                 {item.name}
//             </Link>
//         </li>
//     })}</ul>;
// };
// export default CategoryList;

// export const categoryListQuery = graphql`
//   query {
//     categoryList: allWcProductsCategories {
//       nodes {
//         id
//         name
//         slug
//       }
//     }
//   }
// `


import React from 'react'

export default function index() {
  return (
    <div>index</div>
  )
}
